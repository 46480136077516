<template>
  <div class="page-load-style use-boot">
    <div class="container py-5">

      <!-- 经典版式套用 -->
      <div>
        <h3 class="c">经典版式套用</h3>

        <div class="mt-4">
          <div class="text-muted mb-3">
            说明：如果导入样式不合适或自己配乱了，可重置回项目初始化时的样式状态
            <br>
            [! Demo，准备好经典配置给前端后这里才可用]
          </div>
          <div class="mt-4">

            <div class="list-template d-flex">
              <div
                class="d-inline-block bg-light border p-2 text-muted mr-4 mb-3 d-flex align-items-center"
                v-for="(item,index) of list_template"
                :key="index"
                >
                <span>
                  {{item.name}}
                </span>
                <span class="cp ml-3 c small">
                  编辑
                </span>
                <button
                  @click="useStyle(item)"
                  class="btn btn-outline-primary btn-sm ml-2"
                  >
                  套用
                </button>
              </div>
            </div>
          </div>
        </div>
        <hr class="my-5">
      </div>

      <!-- 导入 -->
      <div>
        <h3 class="c">导入配置</h3>

        <div class="mt-4">
          <div class="text-muted mb-3">
            说明：填写样式来源的book_id，导入后会覆盖原样式并保存。【此功能一般仅适合新创建的项目】
          </div>
          <div>

            <input
              type="text"
              style="width: 300px"
              v-model="input_book_id"
            >
          </div>
          <div class="mt-4">

            <div>
              <button
                @click="loadStyle()"
                class="btn btn-primary px-5"
                >
                导入
                <span v-if="status_loading_style">...</span>
              </button>
            </div>
          </div>
        </div>

        <hr class="my-5">
      </div>

      <!-- 重置 -->
      <div>
        <h3 class="c">重置配置</h3>

        <div class="mt-4">
          <div class="text-muted mb-3">
            说明：如果导入样式不合适或自己配乱了，可重置回项目初始化时的样式状态
          </div>
          <div class="mt-4">

            <div>
              <button
                @click="resetStyle()"
                class="btn btn-primary px-5"
                >
                重置
                <span v-if="status_reseting_style">...</span>
              </button>
            </div>
          </div>
        </div>
        <hr class="my-5">
      </div>

      <!-- 预览 -->
      <div>

        <div class="text-muted mb-3">
          当前配置预览
          <br>
          注意：仅供预览 不要乱改
        </div>

        <div class="mt-3">

          <vue-json-editor
            v-model="$parent.styleData"
            mode="code"
            lang="zh"
            :showBtns="false"
            :expandedOnStart="true"
            style="height: 600px"
          ></vue-json-editor>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
export default {
  name: 'loadStyle',
  components: {
    vueJsonEditor
  },
  props: [],
  data () {
    return {
      input_book_id: '',

      // 经典版式套用
      list_template: [
        {
          name: '【试卷】A3纸/3栏',
        },
        {
          name: '【试卷】A3纸/2栏',
        },
        {
          name: '【答案册】一个描述',
        },
        {
          name: '【教辅】这里都是假的别点了',
        },
      ],

      // 导入
      json_style: {},
      status_loading_style: false,

      // 重置
      status_reseting_style: false,
    };
  },
  computed: {},
  watch: {},
  methods: {

    // 套用
    useStyle (item) {
      this.$message.success(item.name);
    },

    // 重置
    async resetStyle () {
      const v = this;

      v.status_reseting_style = true;
      await v.$parent.init({
        cfg_reset_style: true,
      });
      v.status_reseting_style = false;

      v.$message.success('重置成功');
    },

    // 导入
    async loadStyle () {
      const v = this;

      if (!v.input_book_id) {
        alert('请输入id');
        return;
      }

      v.status_loading_style = true;
      await v.$parent.init({
        book_id_for_load_style: v.input_book_id,
      });
      v.status_loading_style = false;

      v.$message.success('导入成功');
    },
  },
  created () {

  },
  // mounted() {},
  // beforeDestroy() {},
  // update() {},
  // activited() {},
  // beforeRouteUpdate() {},
  // filters: {},
};
</script>

<style lang="scss" scoped>
  .page-load-style {
    /deep/ .jsoneditor-vue{
      height: 100%;
    }
  }
</style>
